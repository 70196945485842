<template>
  <v-container class="pa-0 overflow-auto" style="max-height: 180px;">
    <div v-for="(item, index) in files" :key="index">
      <v-row class="pa-2 mx-auto" :class="[{'grey lighten-4': !backgroundFiles}]" :style="backgroundFiles">
        <v-col cols="8" sm="9" class="my-auto d-flex py-0">
          <v-avatar size="56" color="grey lighten-2 mr-1">
            <v-icon size="18" class="material-icons ">
              article
            </v-icon>
          </v-avatar>
          <div style="width: 90%" class="my-auto">
            <v-col cols="12" class="py-0 text-wrap text-truncate" style="line-height: 16px;">
              <span class="text-subtitle-2"> {{ item.name }}</span>
            </v-col>
            <v-col cols="12" class="py-0" style="line-height: 16px;">
              <span class="text-caption osg-gray--text"> {{ getType(item.type) }}</span>
              <span class="osg-gray--text mx-2 text-caption" v-if="item.size">&middot;</span>
              <span class="text-caption osg-gray--text" v-if="item.size"> {{ getSize(item.size) }} </span>
            </v-col>
          </div>
        </v-col>
        <v-col cols="4" sm="3" class="my-auto text-end py-0 pr-2 pr-sm-3">
          <v-btn v-if="isReimbursement || !isDetail" 
            :color="isDetail ? 'osg-black' : 'error'" icon width="32" small class="mr-1"
            @click="isDetail ? onDownload(item) : onDeleteFile(item.fileId)">
            <v-icon size="22" >
              {{ isDetail ? 'download' : 'delete' }}
            </v-icon>
          </v-btn>
          <v-btn v-if="showPreview && isReimbursement" color="grey darken-1" icon width="32" small 
            @click="onPreview(item, index)">
            <v-icon size="22">
              visibility
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="mx-3 grey lighten-2" v-if="index !== files.length - 1"></v-divider>
    </div>
    
    <FilePreview v-if="filePreview.showModal" :addFilesOnDetail="addFilesOnDetail"
      :filePreview="filePreview" @deleteFile="onDeleteFile($event)"/>
  </v-container>
</template>

<script>
import FilePreview from './FilePreview'
import arcService from '@/services/arc-api.service'
import { getSizeDescription } from '@/utils'
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');

export default {
  name: 'DisplayFiles',
  components: {FilePreview},
  data() {
    return {
      filePreview: {
        showModal: false,
        file: null,
        index: null,
        source: null
      }
    }
  },
  props: {
    files: {
      type: Array
    },
    source: String,
    showPreview: null,
    backgroundFiles: null,
    addFilesOnDetail: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onDeleteFile(id) {
      this.$emit('deleteFile', id)
    },
    getType(type) {
      if (this.addFilesOnDetail) {
        return type.split('/')[1].toUpperCase()
      } else {
        return type && type.toUpperCase() || ''
      }
      
    },
    getSize(size) {
      return isNaN(size) ?
        size
        : getSizeDescription(parseFloat(size))
    },
    onPreview(file, index) {
      this.filePreview.file = file;
      this.filePreview.source = this.source;
      this.filePreview.index = index;
      this.filePreview.showModal = true;
    },
    async onDownload(file) {
      try {
        const response = await arcService.getAuthorizationFile(file.id)
        const fileData = response.data
        const contentType = response.headers['content-type']
        const url = window.URL.createObjectURL(new Blob([fileData], { type: contentType }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.name)
        document.body.appendChild(link)
        link.click()
      } catch(error) { return }
    },
  },
  computed: {
    ...authorizationModule.mapGetters(['authorization', 'isReimbursement']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isDetail() {
      return this.source === 'DETAIL'
    }
  }
}
</script>